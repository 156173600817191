var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * Chaffle
 * Shuffle Characters Randomly.
 * http://blivesta.github.io/chaffle
 * License : MIT
 * Author : blivesta (http://blivesta.com/)
 */
(function (root, factory) {
  exports = factory();
})(exports, function () {
  'use strict';

  function extend() {
    var extended = {};
    var deep = false;

    if (Object.prototype.toString.call(arguments[0]) === "[object Boolean]") {
      deep = arguments[0];
      i++;
    }

    function merge(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          if (deep && Object.prototype.toString.call(obj[prop]) === "[object Object]") {
            extended[prop] = extend(true, extended[prop], obj[prop]);
          } else {
            extended[prop] = obj[prop];
          }
        }
      }
    }

    for (var i = 0; i < arguments.length; i++) {
      var obj = arguments[i];
      merge(obj);
    }

    return extended;
  }

  function Chaffle(element, options) {
    var data = {};
    var dataLang = element.getAttribute("data-chaffle");
    var dataSpeed = element.getAttribute("data-chaffle-speed");
    var dataDelay = element.getAttribute("data-chaffle-delay");
    if (dataLang.length !== 0) data.lang = dataLang;
    if (dataSpeed !== null) data.speed = Number(dataSpeed);
    if (dataDelay !== null) data.delay = Number(dataDelay);
    (this || _global).options = {
      lang: "en",
      speed: 20,
      delay: 100
    };
    (this || _global).options = extend((this || _global).options, options, data);
    (this || _global).element = element;
    (this || _global).text = (this || _global).element.textContent;
    (this || _global).substitution = "";
    (this || _global).state = false;
    (this || _global).shuffleProps = [];
    (this || _global).reinstateProps = [];
  }

  Chaffle.prototype = {
    constructor: Chaffle,
    init: function () {
      var self = this || _global;
      if (self.state) return;
      self.clearShuffleTimer();
      self.clearReinstateTimer();
      self.state = true;
      self.substitution = "";
      self.shuffleProps = [];
      self.reinstateProps = [];
      var shuffleTimer = setInterval(function () {
        self.shuffle();
      }, self.options.speed);
      var reinstateTimer = setInterval(function () {
        self.reinstate();
      }, self.options.delay);
      self.shuffleProps = shuffleTimer;
      self.reinstateProps = reinstateTimer;
    },
    shuffle: function () {
      (this || _global).element.textContent = (this || _global).substitution;
      var textLength = (this || _global).text.length;
      var substitutionLength = (this || _global).substitution.length;

      if (textLength - substitutionLength > 0) {
        for (var i = 0; i <= textLength - substitutionLength; i++) {
          (this || _global).element.textContent = (this || _global).element.textContent + this.randomStr();
        }
      } else {
        this.clearShuffleTimer();
      }
    },
    reinstate: function () {
      var textLength = (this || _global).text.length;
      var substitutionLength = (this || _global).substitution.length;

      if (substitutionLength < textLength) {
        (this || _global).element.textContent = (this || _global).substitution = (this || _global).text.substr(0, substitutionLength + 1);
      } else {
        this.clearReinstateTimer();
      }

      (this || _global).state = false;
    },
    clearShuffleTimer: function () {
      return clearInterval((this || _global).shuffleProps);
    },
    clearReinstateTimer: function () {
      return clearInterval((this || _global).reinstateProps);
    },
    randomStr: function () {
      var str;

      switch ((this || _global).options.lang) {
        case "en":
          str = String.fromCharCode(33 + Math.round(Math.random() * 99));
          break;

        case "ja":
          str = String.fromCharCode(19968 + Math.round(Math.random() * 80));
          break;

        case "ja-hiragana":
          str = String.fromCharCode(12353 + Math.round(Math.random() * 85));
          break;

        case "ja-katakana":
          str = String.fromCharCode(12449 + Math.round(Math.random() * 85));
          break;

        case "ua":
          str = String.fromCharCode(1040 + Math.round(Math.random() * 55));
          break;

        case "cn":
          str = String.fromCharCode(19968 + Math.floor(Math.random() * 20901));
          break;
      }

      return str;
    }
  };
  return Chaffle;
});

export default exports;